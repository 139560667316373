import React from "react"

const Image = ({ title, largeImage, withHover, handleClick = () => { } }) => {
  return (
    <div className='portfolio-item' onClick={handleClick}>
      <div className='hover-bg'>
        {' '}
        <span
          title={title}
          data-lightbox-gallery="gallery1"
        >
          <div className={withHover ? 'hover-text' : ''}>
            <h4>{title}</h4>
          </div>
          <img
            src={largeImage}
            id="gallery-img"
            className="img-responsive"
            alt={title}
          />
        </span>
      </div>
    </div>
  )
}

export default Image
