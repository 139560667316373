import React from "react";
import { Carousel } from 'react-responsive-carousel';
import JsonData from "../data/data.json";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ImageCarousel = ({srcImage}) => {
    return(
        <Carousel showStatus={false} selectedItem={srcImage} >
            {JsonData.Gallery.Images.map((d, i) => (
                <div key={d.largeImage}>
                    <img
                        src={d.largeImage}
                        id = 'gallery-img'
                        className='img-responsive'
                        alt={d.largeImage}
                    />
                </div>
              ))
            }
        </Carousel>
    )
}

export default ImageCarousel
