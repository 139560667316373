export const ContactMessage = ({handleClick}) => {

  return (
    <>
      <div className='section-title'>
        <h2>Contacto</h2>
        <p>
          Se ha cargado su solicitud con éxito, a la brevedad nos estaremos contactando!
        </p>
      </div>
      <button type='button' className='btn btn-custom btn-lg' onClick={handleClick}>
          Volver al form
      </button>
    </>
  )
}
