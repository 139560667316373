import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faWalking, faSpa, faChessBoard, faCakeCandles, faUtensils,
  faScissors, faUserDoctor
} from '@fortawesome/free-solid-svg-icons';

export const Services = (props) => {
  const iconsTranslator = {
    'faWalking': faWalking,
    'faSpa': faSpa,
    'faChessBoard': faChessBoard,
    'faCakeCandles': faCakeCandles,
    'faUtensils': faUtensils,
    'faScissors': faScissors,
    'faUserDoctor': faUserDoctor
  }

  return (
    <div id='services' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Nuestros servicios</h2>
          <p>
            Ofrecemos varios servicios para que puedas disfrutar y sentirte como en casa
          </p>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className='col-md-4'>
                  {' '}
                  <i className={d.icon}>
                  <FontAwesomeIcon icon={iconsTranslator[d.icon]}/>
                  </i>
                  <div className='service-desc'>
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : 'loading'}
        </div>
      </div>
    </div>
  )
}
