import { useState } from "react";
import Image from "./image";
import ImageModal from "./ImageModal";

export const Gallery = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [srcImage, setSrcImage] = useState(null)

  const handleClick = src => {
    setIsOpen(true)
    setSrcImage(src)
    document.body.style.overflow = 'hidden'
  }

  return (
    <div id='portfolio' className='text-center'>
      <div id="installations">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-md-6">
              <img src="img/portada-residencial.jpg" className="img-responsive" alt="" />
            </div>
            <div className="col-xs-12 col-md-6">
              <div className="installations-text">
                <h2>Instalaciones</h2>
                <p>{props.data ? props.data.paragraph : "loading..."}</p>
                <p>{props.data ? props.data.paragraph2 : "loading..."}</p>
                <p>{props.data ? props.data.paragraph3 : "loading..."}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='container'>
        <div className='section-title'>
          <h2>Galería</h2>
        </div>
        <div className='row'>
          <div className='portfolio-items'>
            {props.data
              ? props.data.Images.map((d, i) => (
                <div className='col-sm-6 col-md-4 col-lg-4' key={d.largeImage}>
                  <Image
                    className='gallery-img'
                    title={d.title}
                    largeImage={d.largeImage}
                    handleClick={() => handleClick(i)}
                    withHover={true}
                  />
                </div>
              ))
              : 'Loading...'}
          </div>
        </div>
      </div>
      <ImageModal data={props.data} isOpen={isOpen} setIsOpen={setIsOpen} srcImage={srcImage}/>
    </div>
  )
}
