import React from "react";
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons';
import ImageCarousel from "./ImageCarousel";

const ImageModal = ({data, isOpen, setIsOpen, srcImage}) => {

    const handleClick = () => {
        setIsOpen(false);
        document.body.style.overflow = 'auto'
    }

    return(
        <Modal
            className='image-modal'
            isOpen={isOpen}
            onRequestClose={handleClick}
            ariaHideApp={false}
            shouldCloseOnEsc={true}
            shouldCloseOnOverlayClick={true}
        >
            <FontAwesomeIcon icon={faClose} id='close-modal' onClick={handleClick}/>
            <ImageCarousel
                className='gallery-img'
                srcImage={srcImage}
            />
        </Modal>
    )
}

export default ImageModal
