import { useState } from 'react'
import emailjs from 'emailjs-com'
import { Rings } from  'react-loader-spinner'

export const ContactForm = ({ setSent }) => {

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    try {
      await emailjs.sendForm(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, e.target, process.env.REACT_APP_USER_ID)
      .then((result) => {
        setSent(true)
      })
    } catch (error) {
      console.log(error.text)
    }
  }

  return (
    <div>
        <div className='section-title'>
          <h2>Contacto</h2>
          <p>
            Completa los datos a continuación para enviarnos un correo y nos pondremos en contacto lo más pronto posible.
          </p>
        </div>
        <form name='sentMessage' validate='true' onSubmit={handleSubmit}>
          <div id='contact-section' className='row'>
            <div className='col-md-6'>
              <div className='form-group'>
                <input
                  type='text'
                  id='name'
                  name='name'
                  className='form-control'
                  placeholder='Nombre'
                  required
                  disabled={isLoading}
                />
                <p className='help-block text-danger'></p>
              </div>
            </div>
            <div className='col-md-6'>
              <div className='form-group'>
                <input
                  type='email'
                  id='email'
                  name='email'
                  className='form-control'
                  placeholder='Email'
                  required
                  disabled={isLoading}
                />
                <p className='help-block text-danger'></p>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <textarea
              name='message'
              id='message'
              className='form-control'
              rows='4'
              placeholder='Mensaje'
              required
              disabled={isLoading}
            ></textarea>
            <p className='help-block text-danger'></p>
          </div>
          <div id='success'></div>
          <button type='submit' className='btn btn-custom btn-lg' disabled={isLoading}>
            <div className='form-button-content '>
              {!isLoading
                ? 'Enviar mensaje'
                : <Rings
                    color='white'
                    height="30"
                    width="30"
                  />
              }
            </div>
          </button>
        </form>
    </div>
  )
}
